import { createSlice } from "@reduxjs/toolkit";
import { indexOf } from "lodash";
import moment from "moment";
import { typeOf } from "react-is";
import secureLocalStorage from "react-secure-storage";
import {
  fetchSidebar,
  fetchSidebarNewConversation,
  fetchMessages,
  fetchMoreMessages,
  fetchGroupDetails,
  fetchGroupUsers,
} from "./middleware";

const initialState = {
  scrollTime: null,
  totalMessgesCount: 0,
  allMessageLoaded: false,
  isLoading: false,
  msgLoading: false,
  activeChat: null,
  messagesData: [],
  sidebarData: [],
  sidebarNewConversationData: [],
  groupLoading: false,
  groupsDetails: null,
  groupUsers: [],
  reportUsersData: [],
  blockUsersData: [],
};


const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setActiveChat(state, action) {
      const { payload } = action;
      secureLocalStorage.setItem("activeChat", JSON.stringify(payload));
      state.activeChat = payload;
    },
    setMessageLoading(state, action) {
      const { payload } = action;
      state.msgLoading = payload;
    },
    setImageLoading(state, action) {
      const { payload } = action;
      state.messagesData = [...state.messagesData].map((item, index) => {
        const cloneItem = item;
        if (index === payload) {
          cloneItem.isLoaded = true;
        }
        return cloneItem;
      });
    },
    ackGroupSendDeliveryStatus(state, action) {
      const { payload } = action;
      state.messagesData = [...state.messagesData].map((item) =>
        item._id === payload?._id ? { ...payload } : item
      );
    },
    ackSendAllRead(state, action) {
      const { payload } = action;
      if (payload.receiver_id === state.forwardactiveChat._id) {
        state.messagesData = [...state.messagesData].map((item) =>
          item.delivery_type === 2 ? { ...item, delivery_type: 3 } : item
        );
      }
    },
    ackSendAllDeliver(state, action) {
      const { payload } = action;
      if (payload.receiver_id === state.activeChat._id) {
        state.messagesData = [...state.messagesData].map((item) =>
          item.delivery_type === 1 ? { ...item, delivery_type: 2 } : item
        );
      }
    },
    ackSendDeliveryStatus(state, action) {
      const { payload } = action;
      state.messagesData = [...state.messagesData].map((item) =>
        item._id === payload?._id ? { ...payload } : item
      );
    },
    groupReceiveMessage(state, action) {
      const { payload } = action;
      let newData = state.sidebarData.find(
        (item) => item._id === payload.group_id
      );
      console.log('vrush group', JSON.stringify(newData), '???', payload);
      if (newData) {
        newData.last_message = payload.message;
        newData.last_message_time = payload.createdAt;
        newData.last_media_type = payload.media_type;
        if (state.activeChat?._id !== payload.group_id) {
          newData.unread_msg_count = payload.unread_msg_count;
        }
        state.sidebarData = [...state.sidebarData].map((item) =>
          item._id === newData._id ? { ...newData } : item
        );
      }
      if (state.activeChat?._id === payload.group_id) {
        let temporaryMessageData = [...state.messagesData];
        temporaryMessageData.unshift(payload);
        state.messagesData = temporaryMessageData;
      }

      // if (newData) {
      //   newData.last_message = payload.last_message;
      //   newData.last_message_time = payload.last_message_time;
      //   newData.last_media_type = payload.last_media_type;
      //   state.sidebarData = [...state.sidebarData].map((item) =>
      //     item._id === newData._id ? { ...newData } : item
      //   );
      // }
      // if (state.activeChat?._id === payload.group_id) {
      //   let temporaryMessageData = [...state.messagesData];
      //   temporaryMessageData.unshift(post);
      //   state.messagesData = temporaryMessageData;
      // }
      // if (!newData && payload) {
      //   state.sidebarData = [...state.sidebarData, payload];
      // }
      state.msgLoading = false;
      state.scrollTime = moment().unix();
    },
    ackSendReport(state, action) {
      const { payload } = action;
      let newActiveChat = state.activeChat;
      // i m here
      if (state?.activeChat?._id.toString() === payload.report_id.toString()) {
        newActiveChat.isReported = payload.isReported;
      }
      let newData = state.sidebarData.find(
        (item) => item._id === payload.report_id
      );
      if (newData) {
        newData.isReported = payload.isReported;
        state.sidebarData = [...state.sidebarData].map((item) =>
          item._id === newData._id ? { ...newData } : item
        );
      }
      secureLocalStorage.setItem("activeChat", JSON.stringify(newActiveChat));
      state.msgLoading = false;
      state.activeChat = newActiveChat;
    },
    receiveUpdateUser(state, action) {
      const { payload } = action;
      let newActiveChat = state.activeChat;
      if (state?.activeChat?._id.toString() === payload._id.toString()) {
        newActiveChat.image = payload.user_image;
        secureLocalStorage.setItem("activeChat", JSON.stringify(newActiveChat));
        state.activeChat = newActiveChat;
      }
      let newData = state.sidebarData.find(
        (item) => item._id.toString() === payload._id.toString()
      );
      if (newData) {
        newData.image = payload.user_image;
        state.sidebarData = [...state.sidebarData].map((item) =>
          item._id === newData._id ? { ...newData } : item
        );
      }
    },
    setIsBlockReport(state, action) {
      const { payload } = action;
      if (state.activeChat && state.activeChat.isGroup) {
        const newActiveChat = {
          ...state.activeChat,
          isReported: payload.isReported,
          isBlocked: payload.isBlocked,
        };
        state.activeChat = newActiveChat;
        secureLocalStorage.setItem("activeChat", JSON.stringify(newActiveChat));
      }
    },
    sendOnlineStatus(state, action) {
      const { payload } = action;
      let newActiveChat = state.activeChat;
      if (
        state.activeChat &&
        state.activeChat._id.toString() === payload._id.toString()
      ) {
        newActiveChat.is_online = payload.is_online;
        secureLocalStorage.setItem("activeChat", JSON.stringify(newActiveChat));
        state.activeChat = newActiveChat;
      }

      let newData = state.sidebarData.find(
        (item) => item._id.toString() === payload._id.toString()
      );
      if (newData) {
        newData.is_online = payload.is_online;
        state.sidebarData = [...state.sidebarData].map((item) =>
          item._id === newData._id ? { ...newData } : item
        );
      }
    },
    ackSendBlock(state, action) {
      const { payload } = action;
      let newActiveChat = state.activeChat;
      if (state?.activeChat?._id.toString() === payload.block_id.toString()) {
        newActiveChat.isBlocked = payload.isBlocked;
        secureLocalStorage.setItem("activeChat", JSON.stringify(newActiveChat));
        state.activeChat = newActiveChat;
      }
      let newData = state.sidebarData.find(
        (item) => item._id === payload.block_id
      );
      if (newData) {
        newData.isBlocked = payload.isBlocked;
        state.sidebarData = [...state.sidebarData].map((item) =>
          item._id === newData._id ? { ...newData } : item
        );
      }
      state.msgLoading = false;
    },
    ackReceiveScheduleNormalMessage(state, action) {
      const { payload } = action;
      state.msgLoading = false;
      let temporaryMessageData = [...state.messagesData];
      temporaryMessageData.unshift(payload);
      state.messagesData = temporaryMessageData;
    },
    ackGroupReceiveScheduleNormalMessage(state, action) {
      const { payload } = action;
      state.msgLoading = false;
      let temporaryMessageData = [...state.messagesData];
      temporaryMessageData.unshift(payload);
      state.messagesData = temporaryMessageData;
    },
    ackScheduleNormalMessage(state, action) {
      const { payload } = action;
      state.msgLoading = false;
      state.messagesData = [...state.messagesData].map((item) =>
        item._id === payload._id ? { ...payload } : item
      );
    },
    ackSendForwardMessage(state, action) {
      const { payload } = action;
      var tempDatetime = moment().format("YYYY-MM-DDTHH:mm:ss.sssZ")
      let newPayload = payload[payload.length - 1];
      let newData = state.sidebarNewConversationData.find(
        (item) => item._id === newPayload.receiver_id
      );
      if (newData) {
        newData.last_message = newPayload.message;
        newData.last_message_time = tempDatetime;
        newData.last_media_type = newPayload.media_type;
        var temData = state.sidebarData.filter((items) => items._id === newData._id)
        var temnewData = [...state.sidebarData]
        if (temData.length == 0) {
          temnewData.push(newData)
        }
        else {
          state.sidebarData = [...state.sidebarData].map((item) =>
            item._id === newData._id ? { ...newData } : item
          );
        }

      }
      else {
        newData.last_message = newPayload.message;
        newData.last_message_time = tempDatetime;
        newData.last_media_type = newPayload.media_type;
        var temVar = state.sidebarData
        temVar.push(state.sidebarData)
        // state.sidebarData = [...state.sidebarData].map((item) =>
        // item._id === newData._id ? { ...newData } : item
        // );

      }
      state.messagesData =
        state.activeChat?._id === newPayload.receiver_id
          ? [...state.messagesData, ...payload]
          : state.messagesData;
      state.scrollTime = moment().unix();
    },
    ackGroupSendForwardMessage(state, action) {
      const { payload } = action;
      var tempDatetime = moment().format("YYYY-MM-DDTHH:mm:ss.sssZ")
      var templength = payload.length - 1
      let newPayload = payload[templength];
      let newData = state.sidebarData.find(
        (item) => item._id === newPayload.group_id
      );
      // if (newData) {
      //   newData.last_message = newPayload.message;
      //   newData.last_message_time = newPayload.createdAt;
      //   newData.last_media_type = newPayload.media_type;
      //   state.sidebarData = [...state.sidebarData].map((item) =>
      //     item._id === newData._id ? { ...newData } : item
      //   );
      // }

      if (newData) {
        newData.last_message = newPayload.message;
        newData.last_message_time = tempDatetime;
        newData.last_media_type = newPayload.media_type;
        var temData = state.sidebarData.filter((items) => items._id === newData._id)
        var temnewData = [...state.sidebarData]
        if (temData.length == 0) {
          temnewData.push(newData)
        }
        else {
          state.sidebarData = [...state.sidebarData].map((item) =>
            item._id === newData._id ? { ...newData } : item

          );
        }
      }
      else {
        newData.last_message = payload.message;
        newData.last_message_time = tempDatetime;
        newData.last_media_type = payload.media_type;
        var temVar = state.sidebarData
        temVar.push(state.sidebarData)
        // state.sidebarData = [...state.sidebarData].map((item) =>
        // item._id === newData._id ? { ...newData } : item
        // );
      }
      state.messagesData =
        state.activeChat?._id === payload[0].group_id
          ? [...state.messagesData, ...payload]
          : state.messagesData;

      state.scrollTime = moment().unix();
    },
    receiveForwardMessage(state, action) {
      const { payload } = action;
      var tempDatetime = moment().format("YYYY-MM-DDTHH:mm:ss.sssZ")
      // state.messagesData = [...state.messagesData, ...payload];
      // var tempSidebar = [...state.sidebarData]
      // var temDatas = JSON.stringify(tempSidebar)


      // let newData = [...state.sidebarData].find(
      //   (item) => item._id === payload[0].sender_id
      // );
      // if (newData) {
      //   newData.last_message = payload.message;
      //   newData.last_message_time = payload.createdAt;
      //   newData.last_media_type = payload.media_type;
      //   state.sidebarData = temDatas.map((item) =>
      //     item._id === newData._id ? { ...newData } : item
      //   );
      // }
      // if (state.activeChat?._id === payload[0].sender_id) {
      //   let temporaryMessageData = [...state.messagesData];
      //   var temData_sidebarArray = state.sidebarData
      //    payload.map((items) => temporaryMessageData.unshift(items))
      //   // temporaryMessageData.unshift(payload);
      //   state.messagesData = temporaryMessageData;
      // }
      // if (payload) {
      //   state.sidebarData = [...state.sidebarData, payload];
      // }


      let newData = state.sidebarData.find(
        (item) => item._id === payload[0].sender_id
      );
      if (newData) {
        newData.last_message = payload[0].message;
        newData.last_message_time = tempDatetime;
        newData.last_media_type = payload[0].media_type;
        if (state.activeChat?._id !== payload[0].sender_id) {
          newData.unread_msg_count = payload[0].unread_msg_count;
        }
        state.sidebarData = [...state.sidebarData].map((item) =>
          item._id === newData._id ? { ...newData } : item
        );
      }
      if (state.activeChat?._id === payload[0].sender_id) {
        let temporaryMessageData = [...state.messagesData];
        var temData_sidebarArray = state.sidebarData
        payload.map((items) => temporaryMessageData.unshift(items))
        // temporaryMessageData.unshift(payload);
        state.messagesData = temporaryMessageData;
      }
      state.scrollTime = moment().unix();

    },
    groupReceiveForwardMessage(state, action) {
      const { payload } = action;
      var tempDatetime = moment().format("YYYY-MM-DDTHH:mm:ss.sssZ")
      // state.messagesData = [...state.messagesData, ...payload];
      // state.scrollTime = moment().unix();

      let newData = state.sidebarData.find(
        (item) => item._id === payload[0].group_id
      );
      if (newData) {
        newData.last_message = payload[0].message;
        newData.last_message_time = tempDatetime;
        newData.last_media_type = payload[0].media_type;
        if (state.activeChat?._id !== payload[0].group_id) {
          newData.unread_msg_count = payload[0].unread_msg_count;
        }
        state.sidebarData = [...state.sidebarData].map((item) =>
          item._id === newData._id ? { ...newData } : item
        );
      }
      if (state.activeChat?._id === payload[0].group_id) {
        let temporaryMessageData = [...state.messagesData];
        var temData_sidebarArray = state.sidebarData
        payload.map((items) => temporaryMessageData.unshift(items))
        // temporaryMessageData.unshift(payload);
        state.messagesData = temporaryMessageData;
      }
      state.scrollTime = moment().unix();
    },
    receiveMessage(state, action) {
      const { post, newConversation } = action.payload;
      let newData = state.sidebarData.find(
        (item) => item._id === newConversation._id
      );
      console.log('vrush', JSON.stringify(newData), '???', newConversation, '>>>>>>>>>>>>', state.activeChat?._id);
      if (newData) {
        newData.last_message = newConversation.last_message;
        newData.last_message_time = newConversation.last_message_time;
        newData.last_media_type = newConversation.last_media_type;
        if (state.activeChat?._id !== newConversation._id) {
          newData.unread_msg_count = newConversation.unread_msg_count;
        }
        state.sidebarData = [...state.sidebarData].map((item) =>
          item._id === newData._id ? { ...newData } : item
        );
      }
      if (state.activeChat?._id === newConversation._id) {
        let temporaryMessageData = [...state.messagesData];
        temporaryMessageData.unshift(post);
        state.messagesData = temporaryMessageData;
      }
      if (!newData && newConversation) {
        state.sidebarData = [...state.sidebarData, newConversation];
      }
      state.scrollTime = moment().unix();
      state.msgLoading = false;
    },
    ackSendEditMessage(state, action) {
      const { payload } = action;

      var temdataExap = [...state.messagesData]

      var tempDataFilter = temdataExap.filter((items, index) => {
        if (index === 0 && items._id === payload._id) {
          return items
        }

      })

      state.messagesData = [...state.messagesData].map((item) =>
        item._id === payload._id ? { ...payload } : item
      );

      if (tempDataFilter.length > 0) {
        var newData = state.sidebarData.find(
          (item) => item._id === payload.receiver_id || item._id === payload.group_id
        );

        if (newData) {
          newData.last_message = payload.message;
          newData.last_message_time = payload.schedule_time;
          newData.last_media_type = payload.media_type;
          state.sidebarData = [...state.sidebarData].map((item) =>
            item._id === newData._id ? { ...newData } : item
          );
        }

        state.scrollTime = moment().unix();
        state.msgLoading = false;
      }
    },
    ackSendMessage(state, action) {
      const { post, newConversation } = action.payload;
      let newData = state.sidebarData.find(
        (item) => item._id === newConversation._id
      );

      if (newData) {
        newData.last_message = newConversation.last_message;
        newData.last_message_time = newConversation.last_message_time;
        newData.last_media_type = newConversation.last_media_type;
        state.sidebarData = [...state.sidebarData].map((item) =>
          item._id === newData._id ? { ...newData } : item
        );
      }

      // if (state.activeChat?._id === newConversation._id) {
      //   let temporaryMessageData = [...state.messagesData];
      //   temporaryMessageData.unshift(post);
      //   state.messagesData = temporaryMessageData;
      // }
      if (state.activeChat?._id === newConversation._id) {
        let temporaryMessageData = [...state.messagesData];
        temporaryMessageData.unshift(post);
        state.messagesData = temporaryMessageData;
      }
      if (!newData && newConversation) {
        state.sidebarData = [...state.sidebarData, newConversation];
      }
      state.msgLoading = false;
      state.scrollTime = moment().unix();
    },
    ackGroupSendMessage(state, action) {
      const { payload } = action;
      let newData = state.sidebarData.find(
        (item) => item._id === payload.group_id
      );

      if (newData) {
        newData.last_message = payload.message;
        newData.last_message_time = payload.createdAt;
        newData.last_media_type = payload.media_type;
        state.sidebarData = [...state.sidebarData].map((item) =>
          item._id === newData._id ? { ...newData } : item
        );
      }

      if (state.activeChat?._id === payload.group_id) {
        let temporaryMessageData = [...state.messagesData];
        temporaryMessageData.unshift(payload);
        state.messagesData = temporaryMessageData;
      }

      if (!newData && payload) {
        state.sidebarData = [...state.sidebarData, payload];
      }
      state.msgLoading = false;
      state.scrollTime = moment().unix();
    },
    ackDeleteMessage(state, action) {
      const { payload } = action;
      let newMsgData = state.messagesData.filter(
        (items) => !payload?.data?._ids.includes(items._id.toString())
      );
      var last = newMsgData.length > 0 ? newMsgData[newMsgData.length - 1] : null;
      let newData = state.sidebarData.find(
        (item) => item._id === state.activeChat?._id
      );
      let newSidebarData = state.sidebarData;

      if (newData) {
        newData.last_message = last ? payload?.temp_msg?.message : "";
        newData.last_message_time = last ? payload?.temp_msg?.createdAt : null;
        newData.last_media_type = last ? payload?.temp_msg?.media_type : 0;
        newSidebarData = state.sidebarData.map((item) =>
          item._id === newData?._id ? { ...newData } : item
        );
      }
      if (newSidebarData) {
        newSidebarData.last_message = payload?.temp_msg?.message || "";
        newSidebarData.last_message_time =
          payload?.temp_msg?.last_message_time || "";
        newSidebarData.last_media_type =
          payload?.temp_msg?.last_media_type || 0;
      }
      state.msgLoading = false;
      state.sidebarData = newSidebarData;
      state.messagesData = newMsgData;
    },
    ackGroupDeleteMessage(state, action) {
      const { payload } = action;
      let newMsgData = state.messagesData.filter(
        (items) => !payload?.data?._ids.includes(items._id.toString())
      );
      var last = newMsgData.length > 0 ? newMsgData[newMsgData.length - 1] : null;
      let newData = state.sidebarData.find(
        (item) => item._id === state.activeChat?._id
      );
      let newSidebarData = state.sidebarData;
      if (newData) {
        newData.last_message = last ? payload?.temp_msg?.last_message : "";
        newData.last_message_time = last ? payload?.temp_msg?.last_message_time : null;
        newData.last_media_type = last ? payload?.temp_msg?.last_media_type : 0;
        newSidebarData = state.sidebarData.map((item) =>
          item._id === newData?._id ? { ...newData } : item
        );
      }
      if (newSidebarData) {
        newSidebarData.last_message = payload?.temp_msg?.message || "";
        newSidebarData.last_message_time =
          payload?.temp_msg?.last_message_time || "";
        newSidebarData.last_media_type = payload?.temp_msg?.last_media_type || "";
      }

      state.msgLoading = false;
      state.sidebarData = newSidebarData;
      state.messagesData = newMsgData;
    },
    receiveDeleteMessage(state, action) {
      const { post } = action.payload;
      const dataTemp = action.payload;
      let newMsgData = state.messagesData.filter(
        (items) => !dataTemp?.data?._ids.includes(items._id.toString())
      );
      var last = newMsgData.length > 0 ? newMsgData[newMsgData.length - 1] : null;
      let newData = state.sidebarData.find(
        (item) => item._id === state.activeChat?._id
      );
      let newSidebarData = state.sidebarData;

      if (newData) {
        newData.last_message = last ? dataTemp.temp_msg.message : "";
        newData.last_message_time = last ? dataTemp.temp_msg.createdAt : null;
        newData.last_media_type = last ? dataTemp.temp_msg.media_type : 0;
        newSidebarData = state.sidebarData.map((item) =>
          item._id === newData?._id ? { ...newData } : item
        );
      }
      newSidebarData.last_message = dataTemp.temp_msg.message || "";
      newSidebarData.last_message_time =
        dataTemp.temp_msg.createdAt || "";
      newSidebarData.last_media_type = dataTemp.temp_msg.media_type || 0;
      state.msgLoading = false;
      state.sidebarData = newSidebarData;
      state.messagesData = newMsgData;

    },
    receiveGroupDeleteMessage(state, action) {
      const { payload } = action;
      // let newMsgData = state.messagesData.filter(
      //   (items) => !payload?.data?._ids.includes(items._id.toString())
      // );
      // let newData = state.sidebarData.find(
      //   (item) => item._id === state.activeChat?._id
      // );
      // let newSidebarData = state.sidebarData;
      // if (newData) {
      //   newSidebarData = state.sidebarData.map((item) =>
      //     item._id === newData?._id ? { ...newData } : item
      //   );
      // }
      // newSidebarData.last_message = payload?.temp_msg?.last_message || "";
      // newSidebarData.last_message_time =
      //   payload?.temp_msg?.last_message_time || "";
      // newSidebarData.last_media_type = payload?.temp_msg?.last_media_type || 0;
      // state.msgLoading = false;
      // state.sidebarData = newSidebarData;
      // state.messagesData = newMsgData;

      console.log("payload delete", payload)
      let newMsgData = state.messagesData.filter(
        (items) => !payload?.data?._ids.includes(items._id.toString())
      );
      var last = newMsgData.length > 0 ? newMsgData[newMsgData.length - 1] : null;
      let newData = state.sidebarData.find(
        (item) => item._id === state.activeChat?._id
      );
      let newSidebarData = state.sidebarData;
      if (newData) {
        newData.last_message = last ? payload?.temp_msg?.last_message : "";
        newData.last_message_time = last ? payload?.temp_msg?.last_message_time : null;
        newData.last_media_type = last ? payload?.temp_msg?.last_media_type : 0;
        newSidebarData = state.sidebarData.map((item) =>
          item._id === newData?._id ? { ...newData } : item
        );
      }
      if (newSidebarData) {
        newSidebarData.last_message = payload?.temp_msg?.last_message || "";
        newSidebarData.last_message_time =
          payload?.temp_msg?.last_message_time || "";
        newSidebarData.last_media_type = payload?.temp_msg?.last_media_type || "";
      }

      state.msgLoading = false;
      state.sidebarData = newSidebarData;
      state.messagesData = newMsgData;
    },
    ackSendDeleteChat(state, action) {
      const { payload } = action;
      let newData = state.sidebarData.find(
        (item) => item._id.toString() === payload._id.toString()
      );
      let newSidebarData = state.sidebarData;
      if (newData) {
        newData.last_message = "";
        newData.last_message_time = "";
        newData.last_media_type = "";
        newSidebarData = state.sidebarData.map((item) =>
          item._id === newData._id ? { ...newData } : item
        );
      }
      state.activeChat = null;
      secureLocalStorage.removeItem("activeChat");
      state.sidebarData = newSidebarData;
      state.messagesData = [];
    },
    setGroupLoading(state, action) {
      state.groupLoading = true;
    },
    resetGroupDetails(state, action) {
      let data = {
        group_name: "",
        description: "",
        group_image: "",
        group_users: [],
      };
      state.groupsDetails = data;
      state.groupLoading = false;
    },
    addNewSidebarData(state, action) {
      const { payload } = action;
      console.log("payload", payload)
      let newData = state.sidebarData.find((item) => item._id === payload._id);
      if (!newData) {
        state.sidebarData = [...state.sidebarData, payload];
      }
    },
    addNewSidebarDataNew(state, action) {
      const { payload } = action;
      console.log("payload", payload)
      // let newData = state.sidebarData.find((item) => item._id === payload._id);

      state.sidebarData = payload

    },
    deleteGroupSuccess(state, action) {
      const { payload } = action;
      state.sidebarData = [...state.sidebarData].filter(
        (items) => payload._id !== items._id
      );

      if (state?.activeChat?._id.toString() === payload._id.toString()) {
        state.activeChat = null;
        secureLocalStorage.removeItem("activeChat");
      }
    },
    receiveGroupAdmin(state, action) {
      const { payload } = action;
      if (state?.activeChat?._id.toString() === payload.group_id.toString()) {
        let newData = state.groupUsers.find(
          (item) => item._id === payload.post.member_id.toString()
        );
        if (newData) {
          newData.role = payload?.role || 0;
        }
        state.groupUsers = state.groupUsers.map((item) =>
          item._id === newData._id ? { ...newData } : item
        );
        state.groupLoading = false;
      }
    },
    addGroupMemberSuccess(state, action) {
      const { payload } = action;
      const group_details = payload.group_details;
      if (state?.activeChat?._id.toString() === group_details._id.toString()) {
        let newData = state.groupUsers.find(
          (item) => item._id === payload.post.member_id.toString()
        );
        if (newData) {
          newData.role = payload?.role || 1;
        }

        state.groupUsers = [...state.groupUsers].map((item) =>
          item._id === newData._id ? { ...newData } : item
        );

        if (payload && payload.groupPost) {
          let temporaryMessageData = [...state.messagesData];
          temporaryMessageData.unshift(payload.groupPost);
          state.messagesData = temporaryMessageData;
        }
        state.groupLoading = false;
      }

      let isAvailable = state.sidebarData.find(
        (item) => item._id === group_details._id
      );
      if (!isAvailable) {
        let newData = {
          _id: group_details._id,
          name: group_details.group_name,
          image: group_details.group_image ? group_details.group_image : "",
          last_message: group_details.last_message,
          last_message_time: group_details.last_message_time
            ? group_details.last_message_time
            : group_details.createdAt,
          last_media_type: group_details.last_media_type,
          isGroup: 1,
        };
        state.sidebarData = [...state.sidebarData, newData];
      }
    },
    removeGroupMemberSuccess(state, action) {
      const { payload } = action;
      const group_details = payload.group_details;
      if (state?.activeChat?._id.toString() === group_details._id.toString()) {
        let newData = state.groupUsers.find(
          (item) => item._id === payload.post.member_id.toString()
        );
        if (newData) {
          newData.role = payload.role;
        }
        state.groupUsers = [...state.groupUsers].map((item) =>
          item._id === newData._id ? { ...newData } : item
        );

        if (payload && payload.groupPost) {
          let temporaryMessageData = [...state.messagesData];
          temporaryMessageData.unshift(payload.groupPost);
          state.messagesData = temporaryMessageData;
        }
        state.groupLoading = false;
      }
    },
    createGroupSuccess(state, action) {
      const { payload } = action;
      let newData = {
        _id: payload._id,
        name: payload.group_name,
        image: payload.group_image ? payload.group_image : "",
        last_message: payload.last_message,
        last_message_time: payload.last_message_time
          ? payload.last_message_time
          : payload.createdAt,
        last_media_type: payload.last_media_type,
        isGroup: 1,
      };
      state.sidebarData = [...state.sidebarData, newData];
      state.groupLoading = false;
    },
    editGroupSuccess(state, action) {
      const { payload } = action;
      let newActiveChat = state.activeChat;
      if (state?.activeChat?._id.toString() === payload._id.toString()) {
        newActiveChat.name = payload.group_name;
        newActiveChat.image = payload.group_image;
      }

      let newData = state.sidebarData.find((item) => item._id === payload._id);
      if (newData) {
        newData.name = payload.group_name;
        newData.image = payload.group_image;
        state.sidebarData = [...state.sidebarData].map((item) =>
          item._id === newData._id ? { ...newData } : item
        );
      }
      state.groupLoading = false;
      secureLocalStorage.removeItem("activeChat");
      state.activeChat = newActiveChat;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSidebar.fulfilled, (state, action) => {
        const { payload } = action;
        let newChat = payload.sidebarData.find(
          (user) => user._id === state?.activeChat?._id
        );
        if (newChat) {
          secureLocalStorage.setItem("activeChat", JSON.stringify(newChat));
        } else {
          secureLocalStorage.removeItem("activeChat");
        }
        return {
          ...state,
          activeChat: newChat ? newChat : null,
          sidebarData: payload.sidebarData,
          isLoading: false,
        };
      })
      .addCase(fetchSidebar.rejected, (state, action) => {
        return {
          ...state,
          sidebarData: [],
          isLoading: false,
        };
      })
      .addCase(fetchSidebarNewConversation.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          sidebarNewConversationData: payload.sidebarData,
          isLoading: false,
        };
      })
      .addCase(fetchSidebarNewConversation.rejected, (state, action) => {
        return {
          ...state,
          sidebarNewConversationData: [],
          isLoading: false,
        };
      })
      .addCase(fetchMessages.pending, (state, action) => {
        return {
          ...state,
          msgLoading: true,
          totalMessgesCount: 0,
          allMessageLoaded: false,
          messagesData: [],
        };
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          scrollTime: moment().unix(),
          allMessageLoaded: true,
          messagesData: payload.conversationsData,
          totalMessgesCount: payload.totalMessgesCount,
          msgLoading: false,
        };
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        return {
          ...state,
          messagesData: [],
          msgLoading: false,
          totalMessgesCount: 0,
        };
      })
      .addCase(fetchMoreMessages.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          messagesData: state.messagesData.concat(payload.conversationsData),
          totalMessgesCount: payload.totalMessgesCount,
        };
      })
      .addCase(fetchGroupDetails.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          groupsDetails: payload.groupsDetails,
          groupLoading: false,
        };
      })
      .addCase(fetchGroupDetails.rejected, (state, action) => {
        return {
          ...state,
          groupsDetails: null,
          groupLoading: false,
        };
      })
      .addCase(fetchGroupUsers.pending, (state, action) => {
        return {
          ...state,
          groupLoading: true,
        };
      })
      .addCase(fetchGroupUsers.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          groupUsers: payload.groupUsers,
          groupLoading: false,
        };
      })
      .addCase(fetchGroupUsers.rejected, (state, action) => {
        return {
          ...state,
          groupUsers: [],
          groupLoading: false,
        };
      });
  },
});

export const {
  setActiveChat,
  setMessageLoading,
  setImageLoading,
  ackGroupSendDeliveryStatus,
  ackSendDeliveryStatus,
  receiveMessage,
  groupReceiveMessage,
  ackSendReport,
  sendOnlineStatus,
  receiveUpdateUser,
  ackSendBlock,
  ackSendForwardMessage,
  ackGroupSendForwardMessage,
  receiveForwardMessage,
  groupReceiveForwardMessage,
  ackSendEditMessage,
  ackSendMessage,
  ackGroupSendMessage,
  ackDeleteMessage,
  ackGroupDeleteMessage,
  receiveDeleteMessage,
  receiveGroupDeleteMessage,
  ackSendDeleteChat,
  setGroupLoading,
  resetGroupDetails,
  setIsBlockReport,
  createGroupSuccess,
  deleteGroupSuccess,
  editGroupSuccess,
  receiveGroupAdmin,
  addGroupMemberSuccess,
  removeGroupMemberSuccess,
  addNewSidebarData,
  addNewSidebarDataNew,
  ackScheduleNormalMessage,
  ackGroupReceiveScheduleNormalMessage,
  ackReceiveScheduleNormalMessage,
  ackSendAllDeliver,
  ackSendAllRead,
} = chatSlice.actions;

export default chatSlice.reducer;
