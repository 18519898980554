import axiosInstance from "./apis";
import { apisUrls } from "./apisUrls";
import axios from "axios";

export const fetchSidebarService = async () => {
  return await axiosInstance
    .get(apisUrls.sidebar_list)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const fetchSidebarNewConversationService = async () => {
  return await axiosInstance
    .get(apisUrls.sidebar_new_conversations_list)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const conversationUserUpdateStatus = async (data) => {
  return await axiosInstance
    .get(`/conversation/user/update/status`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const conversationUserReadUpdateStatus = async (_id) => {
  return await axiosInstance
    .get(`/conversation/user/update/status/read/${_id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const conversationGroupMessageDeliveryDetails = async (_id) => {
  return await axiosInstance
    .get(`/conversation/group/message/info/${_id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const conversationGroupReadUpdateStatus = async (_id) => {
  return await axiosInstance
    .get(`conversation/group/updateMsg/${_id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const fetchMessagesService = async (data) => {
  var myUrl = data.isGroup
    ? apisUrls.message_group_list
    : apisUrls.message_user_list;
  return await axiosInstance
    .get(`${myUrl}/${data._id}?page=${data?.page || 1}&size=20`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const fetchGroupDetailsService = async (group_id) => {
  return await axiosInstance
    .get(`${apisUrls.group}/details/${group_id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const fetchGroupUserRoleService = async (group_id) => {
  return await axiosInstance
    .get(`${apisUrls.fetch_group_users_role}/${group_id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const fetchGroupUsersService = async (group_id) => {
  return await axiosInstance
    .get(`${apisUrls.fetch_group_users}/${group_id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getPushTokenService = async (data) => {
  var formData = new FormData();
  formData.append("token", data.token);

  var config = {
    method: "POST",
    url: `${process.env.REACT_APP_SMS_BASE_URL}push_notification_token.php`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "cache-control": "no-cache",
      "postman-token": "55fe1d91-fd3c-82fd-7a27-70f33599a8ad",
    },
    data: formData,
  };

  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updatePushTokenService = async (data) => {
  return await axiosInstance
    .put(`${apisUrls.update_user_push_token}/${data._id}`, {
      push_token: data.push_token,
      selector: data.selector,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
