import secureLocalStorage from "react-secure-storage";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export const getLocalUserDetails = async () => {
  var user = JSON.parse(await secureLocalStorage.getItem("chat_user"));
  return user ? user : null;
};

export const getToken = async () => {
  var user = JSON.parse(await secureLocalStorage.getItem("chat_user"));
  console.log(user,"userforgettingtoken")
  cookies.set("userforattach",user?.is_send_sms_attachement)
  return user ? user.token : null;
};

export const setToken = async (user) => {
  await secureLocalStorage.setItem("chat_user", JSON.stringify(user));
};
