export const apisUrls = {
  login: "/user/login",
  logout: "/user/logout",
  user_profile: "/user/profile",
  update_user_profile: "/user/profile",
  update_user_push_token: "/user/push_token",
  fetch_users: "/user",
  sidebar_list: "/sidebar",
  sidebar_new_conversations_list: "/sidebar/newchat",
  message_user_list: "/conversation/user",
  message_user_delete: "/conversation/delete",
  message_group_list: "/conversation/group",
  message_group_delete: "/conversation/group/delete",
  block_user_list: "/block/user",
  block_user_toggle: "/block/user/toggle",
  report_user_list: "/report/user",
  report_user_toggle: "/report/user/toggle",
  group: "/group",
  fetch_group_users: "/group/list/users",
  fetch_group_users_role: "/group/role/user",
  group_member_toggle: "/group/toggle/member",
  group_admin_toggle: "/group/toggle/admin",
};
