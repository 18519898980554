import React, { useEffect, useState, lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { MyLayout } from "../layout/MyLayout";
import { getToken } from "../store/services/token.service";

const LoginScreen = lazy(() => import("../pages/Login"));
const LoginPage = lazy(() => import("../pages/LoginPage"));
const ChatScreen = lazy(() => import("../pages/Chat/Chat"));
const SMSScreen = lazy(() => import("../pages/SMS/SMS"));
const NotFoundScreen = lazy(() => import("../pages/NotFound"));

const AppNavigator = () => {
  const [isAuthorised, setAuthorised] = useState(true);
  useEffect(() => {
    async function checkUserLoggedIn() {
      var token = await getToken();
      if (!token) {
        setAuthorised(false);
      }
    }
    checkUserLoggedIn();
  }, []);

  return (
    <Suspense fallback={<div />}>
      <Routes>
        <Route path="/" element={<LoginScreen />} />

        <Route
          path="/chat"
          element={
            isAuthorised ? (
              <MyLayout>
                <ChatScreen />
              </MyLayout>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />

        {/* <Route
          path="/login"
          element={
            isAuthorised ? (
              <MyLayout>
                <LoginScreen />
              </MyLayout>
            ) : (
              <Navigate to="/" replace />
            )
          }
        /> */}

        <Route
          path="/sms"
          element={
            isAuthorised ? (
              <MyLayout>
                <SMSScreen />
              </MyLayout>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />

        <Route
          path="*"
          element={
            <MyLayout>
              <NotFoundScreen />
            </MyLayout>
          }
        />
      </Routes>
    </Suspense>
  );
};
export default AppNavigator;
