import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchGroupDetailsService,
  fetchGroupUsersService,
  fetchMessagesService,
  fetchSidebarNewConversationService,
  fetchSidebarService,
} from "../../services/chat.service";

export const fetchSidebar = createAsyncThunk("fetchSidebar", async () => {
  const response = await fetchSidebarService();
  return response.data;
});

export const fetchSidebarNewConversation = createAsyncThunk(
  "fetchSidebarNewConversation",
  async () => {
    const response = await fetchSidebarNewConversationService();
    return response.data;
  }
);

export const fetchMessages = createAsyncThunk("fetchMessages", async (data) => {
  const response = await fetchMessagesService(data);
  return response.data;
});

export const fetchMoreMessages = createAsyncThunk(
  "fetchMoreMessages",
  async (data) => {
    const response = await fetchMessagesService(data);
    return response.data;
  }
);

export const fetchGroupDetails = createAsyncThunk(
  "fetchGroupDetails",
  async (data) => {
    const response = await fetchGroupDetailsService(data);
    return response.data;
  }
);

export const fetchGroupUsers = createAsyncThunk(
  "fetchGroupUsers",
  async (group_id) => {
    const response = await fetchGroupUsersService(group_id);
    return response.data;
  }
);
